
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'DialogsBaseSection',
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      closeButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      customStyle: {
        type: String,
        required: false,
        default: 'default',
      },
    },
    data() {
      return {};
    },
  };

export function isHttpUrl(url) {
  return /^https*:\/\/+/.test(url);
}

function isGoogleCloudStorageUrl(url) {
  return url.startsWith(process.env.STRAPI_GOOGLE_CLOUD_URL);
}

function editStrapiUrl(url) {
  return process.env.STRAPI_CONTENT_URL
    ? url.replace(
        process.env.STRAPI_GOOGLE_CLOUD_URL,
        process.env.STRAPI_CONTENT_URL
      )
    : url;
}

export function prepareImageUrl(url) {
  return isGoogleCloudStorageUrl(url)
    ? editStrapiUrl(url)
    : isHttpUrl(url)
    ? url
    : process.env.STRAPI_URL + url;
}


  import ScreenSize from '@/mixins/ScreenSize';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    name: 'RegistrationSuccessPage',
    components: {
      CardWithImage: () => import('@/components/elements/CardWithImage'),
      RichText: () => import('@/components/elements/RichText'),
      DialogsBaseSection,
    },
    mixins: [ScreenSize],
    data() {
      return {
        section: {},
        cardsLength: 0,
      };
    },
    async fetch() {
      this.section = await this.$cachedStrapi
        .find('organization-registration-success-dialog')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
      this.cardsLength =
        this.section && this.section.cards ? this.section.cards.length : 0;
    },
    computed: {
      imageUrl() {
        return this.section && this.section.image
          ? prepareImageUrl(this.section.image.url)
          : require(process.env.NUXT_ENV_FALLBACK_CARD_IMAGE);
      },
    },
  };
